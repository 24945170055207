@import "../../../styles/helpers";
.titleW {
    font-size: 20px;
    color: #fbc000;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #636363;
    margin-right: 24px;
    margin-bottom: 20px; }
.body {
    margin-top: 36px;
    padding: 0 0 24px 24px;
    border-radius: 16px;
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
    border: 1px solid $neutrals6;
    @include m {
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; } }
.parentsBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include m {
        margin-left: -16px;
        margin-right: -16px;
        padding: 0px; }
    .parentsItem {
        padding: 14px;
        margin-right: 12px;
        margin-bottom: 12px;
        border-radius: 16px;
        box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
        width: 114px;
        background: $neutrals2;
        border-color: $neutrals3;
        h3 {
            color: #ffffff;
            font-size: 12px; }
        img {
            width: 100%; } }
    .parentsItem:nth-child(3n) {
        margin-right: 0; } }
