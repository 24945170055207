@import "../../styles/helpers";
@import "../../styles/helpers";

.container {
    position: relative;
    width: 65%;
    margin: 180px auto;
    min-height: 400px;
    border-radius: 20px;
    padding: 1rem;
    @media only screen and (max-width: "474px") {
        width: 88%;
        margin: 20px auto; }
    .loginBox {
        width: 380px;
        margin: 0 auto;
        padding: 32px 20px;
        border: 1px solid #3a3f50;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        margin-bottom: 20px;
        .btnItem {
            width: 100%!important;
            margin-bottom: 0!important; }
        .input {
            background: none;
            border: 2px solid #353945;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            width: 100%;
            height: 48px;
            line-height: 48px;
            margin-bottom: 20px;
            color: #ffffff;
            padding-left: 15px; } }


    .card {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 16px;
        .title {
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            min-width: 0px;
            width: 100%;
            display: flex;
            padding: 0px;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            margin: -8px; }
        .borderBox {
            box-sizing: border-box;
            text-align: center;
            margin: 0px;
            min-width: 0px;
            width: 100%;
            border-radius: 16px;
            padding: 40px;
            min-height: 370px;
            button {
                width: 100%;
                max-width: 380px;
                border-radius: 5px;
                margin-bottom: 20px;
                background: #fbc000;
                color: #000;
                &:hover {
                    background: #fbc000;
                    color: #000; }
                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px; } }
            .normal {
                background: transparent;
                border: 1px solid #ccc;
                border-radius: 5px; }
            p {
                box-sizing: border-box;
                margin: 0px;
                min-width: 0px;
                font-weight: 400;
                font-size: 16px;
                color: rgb(136, 141, 155);
                text-align: center; } } } }
