@import "../../../styles/helpers";
.titleW {
    font-size: 20px;
    color: #fbc000;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #636363;
    margin-right: 24px;
    margin-bottom: 20px; }
.body {
    margin-top: 36px;
    padding: 0 0 24px 24px;
    border-radius: 16px;
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
    border: 1px solid $neutrals6;
    @include m {
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; }
    .flexBox {
        display: flex;
        justify-content: flex-start;
        .flexItem {
            width: 49%;
            height: 50px;
            display: flex;
            justify-content: flex-start;
            .appearanceAttr {
                color: #fff;
                span {
                    color: #6b7185;
                    font-size: 12px; } }
            img {
                width: 50px;
                height: 50px;
                margin-right: 20px; } } } }



