@import "../../styles/helpers";
.container {
    padding: 0; }
.top {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    padding-bottom: 32px;
    border-bottom: 1px solid $neutrals6;
    @include t {
        display: block;
        margin-bottom: 32px;
        padding-bottom: 0;
        border: none; }
    @include m {
        margin-bottom: 24px; }
    @include dark {
        border-color: $neutrals3; } }

.title {
    margin-right: auto;
    @include body-1;
    @include t {
        display: none; } }

.search {
    position: relative;
    flex-shrink: 0;
    width: 320px;
    margin-left: 30px;
    @include t {
        width: 100%;
        margin: 0; } }

.input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 12px;
    background: none;
    border: 2px solid $neutrals6;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $blue;
    transition: background .2s;
    svg {}
    &:hover {
        background: darken($blue, 10); } }

.sorting {
    margin-right: 20px;
    align-items: center;
    .label {
        opacity: 0; }
    @include t {
        display: block; }
    @include m {
        margin-bottom: 32px; }
    .dropdown {
        width: 256px;
        margin-right: auto;
        @include t {
            width: 100%;
            margin: 0 0 32px; } } }

.nav {
    display: flex;
    padding-bottom: 40px;
    @include m {
        margin: 0 -32px;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    cursor: pointer;
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }

.row {
    display: flex;
    @include t {
        display: block; } }

.wrapper {
    flex-grow: 1;
    background: #212030;
    border-radius: 5px;
    padding: 0 20px; }

.filters {
    flex-shrink: 0;
    width: 256px;
    @include t {
        width: 100%;
        margin-bottom: 32px; } }
.topWrap {
    display: flex;
    padding-bottom: 40px;
    flex-wrap: wrap;
    @media only screen and (max-width: "474px") {
        padding-bottom: 0; }
    .topLeft {
        width: 50%;
        font-size: 28px;
        @media only screen and (max-width: "474px") {
            width: 100%;
            padding-left: 12px; } }
    .topRight {
        width: 50%;
        display: flex;
        justify-content: right;
        @media only screen and (max-width: "474px") {
            width: 100%;
            padding-top: 20px;
            padding-left: 12px;
            justify-content: start;
            .range {
                width: 86%;
                .priceBox {
                    padding-right: 12px; } }
            .label {
                display: none; } } } }
.range {
    width: 50%; }

.scale {
    display: flex;
    justify-content: space-between;
    font-weight: 500; }

.group {
    margin-bottom: 24px;
    padding: 24px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.item {
    &:not(:last-child) {
        margin-bottom: 24px; } }
.priceBox {
    display: flex;
    justify-content: flex-start;
    .centerBox {
        height: 48px;
        line-height: 48px;
        width: 20%;
        text-align: center; }
    .minBox {
        width: 40%; }
    .maxBox {
        width: 40%; } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.reset {
    display: inline-flex;
    align-items: center;
    @include button-2;
    cursor: pointer;
    transition: color .2s;
    svg {
        margin-right: 8px;
        @include dark; }
    &:hover {
        color: $blue; } }

.list {
    display: flex;
    flex-wrap: wrap;
    @include m {
        display: block;
        margin: 0; } }

.card {
    display: flex;
    flex: 0 0 calc(100%);
    max-width: calc(100%);
    padding: 12px;
    justify-content: space-between;
    border-bottom: 1px solid #2e2e3a;
    .cardCow {
        width: 20%; }
    @include m {
        max-width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 32px; } } }
.noData {
    text-align: center;
    height: 60px;
    width: 100%;
    line-height: 60px; }
.btns {
    margin-top: 32px;
    text-align: center; }
.listTop {
    display: flex;
    color: #fff;
    padding: 30px 12px 0;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    line-height: 66px;
    .topItem {
        text-align: center;
        width: 20%;
        overflow: hidden; }
    .topItem2 {
        text-align: center;
        width: 20%;
        overflow: hidden;
        @media only screen and (max-width: "474px") {
            width: 1px; } }
    .topItem1 {
        text-align: center;
        width: 20%;
        @media only screen and (max-width: "474px") {
            width: 40%;
            text-align: right; } } }
