@import "../../styles/helpers";
.rules {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 65%;
    margin: 0 auto;
    @media only screen and (max-width: "474px") {
       width: 88%; }
    h3 {
        height: 30px;
        line-height: 30px;
        color: #ffffff;
        font-size: 18px; }
    ul {
        padding-top: 22px; }
    li {
        line-height: 24px;
        font-size: 14px;
        color: #ffffff; } }
@keyframes mymove {
    0% {
        transform: scale(1); }
    25% {
        transform: scale(1.3); }
    50% {
        transform: scale(1); }
    75% {
       transform: scale(1.3); } }
@-webkit-keyframes mymove {
    0% {
        transform: scale(1); }
    25% {
        transform: scale(1.3); }
    50% {
        transform: scale(1); }
    75% {
        transform: scale(1.3); } }
.pageLoading {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #191c23;
    z-index: 10000;
    img {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 50%;
        top: 50%;
        margin-top: -50px;
        margin-left: -50px;
        animation: mymove 5s infinite;
        -webkit-animation: mymove 5s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out;
        -webkit-animation: mymove 5s infinite;
        -webkit-animation-direction: alternate;
        -webkit-animation-timing-function: ease-in-out; } }
.fontGreen {
    font-weight: bold;
    font-size: 18px;
    color: #fbc000!important; }
.btnMin {
    height: 30px;
    background: #fbc000;
    border-radius: 5px;
    color: #000;
    padding: 0 10px;
    font-size: 12px;
    &:hover {
        background: #fbc000; } }
.disableBtn {
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    background: #cccccc;
    font-size: 12px; }
.disableBtn:hover {
    height: 30px;
    padding: 0 10px;
    background: #cccccc; }
.noData {
    text-align: center; }
.tableWrap {
    width: 65%;
    margin: 40px auto;
    border: 1px solid #ffffff;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    padding: 24px 36px;
    overflow-x: auto;
    @media only screen and (max-width: "474px") {
      width: 88%; }
    table {
      width: 100%;
      min-width: 720px; }
    td {
      padding: 12px 0;
      color: #ffffff;
      text-align: right;
      vertical-align: middle;
      button {
          margin-right: 10px; }
      span {
        display: block;
        color: #777E90; } }

    td:nth-child(2) {
      text-align: center; }
    td:first-child {
      text-align: left; } }
.assetsMax {
    color: #fff;
    background: #fbc000;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    min-width: 46px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px; }

.tokenSelect {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid #f1f3f7;
    border-radius: 8px;
    padding: 0 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: 32px;
        margin-right: 10px; }
    .arrow {
        border-right: 1px solid #f1f3f7;
        border-top: 1px solid #f1f3f7;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: 19px; }
    .label {
        width: 20%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .label1 {
        width: 29%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .inputWrap {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputWrap1 {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputItem {
        width: 59%;
        display: flex;
        justify-content: space-between; }
    .inputItem1 {
        width: 39%;
        display: flex;
        justify-content: flex-start; }
    .input {
        background: none;
        border: 2px solid #353945;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: #ffffff;
        padding-left: 15px; }
    .input1 {
        background: none;
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: #ffffff;
        padding-left: 15px; } }
.selectTab {
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    span.active {
        color: #fff;
        background-color: #fbc000; }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        font-size: 14px;
        margin-right: 18px;
        color: rgba(60,60,67,.6);
        background-color: #f0f1f5;
        padding: 6px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        margin-bottom: 12px; } }
.titleTop {
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    padding: 60px 0 40px; }
.row1 {
    text-align: center;
    padding: 30px; }
.row {
    display: flex;
    justify-content: flex-start;
    &:first-child {
        padding: 0 0 22px;
        border-bottom: 1px solid $neutrals6;
        font-weight: 500;
        @include dark {
            border-color: $neutrals3; } }
    &:nth-child(2) {
        padding-top: 22px; } }
.col {
    display: flex;
    justify-content: space-between;
    .label {
        width: 20%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .label1 {
        width: 29%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .inputWrap {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputWrap1 {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputItem {
        width: 59%;
        display: flex;
        justify-content: space-between; }
    .inputItem1 {
        width: 39%;
        display: flex;
        justify-content: flex-start; }
    .input {
        background: none;
        border: 2px solid #353945;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: #ffffff;
        padding-left: 15px; }

    &:first-child {
        color: $neutrals4;
        width: 80%; }

    &:nth-child(2) {
        margin-left: auto;
        padding-left: 30px;
        font-weight: 500; } }
.apyBox {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    background: #2e2e3a;
    border-radius: 10px;
    padding: 0 10px; }
.btns {
    margin-top: 32px; }

.button {
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 8px; } }
.dropdown {
    width: 256px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.dropdown1 {
    width: 166px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.pageWrap {
    position: relative;
    width: 65%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: "474px") {
        width: 88%; } }
.pageWrapDetail {
    padding-top: 50px;
    position: relative;
    width: 65%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: "474px") {
        width: 88%; } }
.title {
    color: #ffffff;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: left;
    position: relative;
    .balance {
        position: absolute;
        right: 0;
        top: 12px;
        color: #999999;
        font-size: 12px; } }
.tokenList {
    .titleModel {
        text-align: center;
        padding-bottom: 20px; } }
.table {
    .row {
        display: flex;
        padding: 0 0 22px;
        justify-content: flex-start;
        &:first-child {
            border-bottom: 1px solid $neutrals6;
            font-weight: 500;
            @include dark {
                border-color: $neutrals3; } }
        &:nth-child(2) {
            padding-top: 22px; } } }
.btnItem {
    position: absolute;
    left: 50%;
    bottom: 20px;
    background: #fbc000;
    color: #000;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    transform: translateX(-50%);
    &:hover {
        background: #fbc000;
        color: #000; } }
.btnItem2 {
    margin: 40px auto;
    background: #fbc000;
    color: #000;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    &:hover {
        background: #fbc000;
        color: #000; } }

.btnItem1 {
    position: absolute;
    left: 74%;
    bottom: 20px;
    background: #fbc000;
    color: #000;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    transform: translateX(-50%);
    &:hover {
        background: #fbc000;
        color: #000; } }

.btnItemGrey {
    position: absolute;
    left: 50%;
    bottom: 20px;
    background: #cccccc;
    transform: translateX(-50%); }
.container {
    position: relative;
    width: 45%;
    min-height: 200px;
    box-shadow: #e0e0e0 0px 2px 10px;
    border-radius: 20px;
    padding: 1rem;

    @media only screen and (max-width: "474px") {
        width: 100%;
        margin: 20px auto; }
    .card {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 16px;
        .borderBox1 {
            box-sizing: border-box;
            text-align: center;
            position: relative;
            margin: 0px;
            min-width: 0px;
            width: 100%;
            border-radius: 16px;
            padding: 40px;
            min-height: 240px;
            border: 1px solid rgb(245, 243, 243); }
        .borderBox {
            box-sizing: border-box;
            position: relative;
            margin: 0px;
            min-width: 0px;
            width: 100%;
            border-radius: 16px;
            padding: 40px;
            min-height: 240px;
            display: flex;
            justify-content: flex-start;
            border: 1px solid rgb(245, 243, 243); }


        .iconToken {
            width: 50px;
            height: 50px;
            margin-top: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px;
            margin-right: 25px; }
        .title1 {
            color: #ffffff;
            font-size: 14px;
            padding-top: 24px;
            text-align: center; }
        .iconToken1 {
            width: 100px;
            height: 100px;
            margin-top: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px; }
        .rightBox {
            width :-webkit-calc(100% - 75px) {}
            width: -moz-calc(100% - 75px);
            width: calc(100% - 75px);
            .income {
                font-size: 30px;
                font-weight: bold;
                color: #fbc000; }
            .title {
                color: #ffffff;
                font-size: 12px; }
            .lock {
                color: #777E90; } } } }
