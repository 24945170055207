@import "../../../styles/helpers";
.titleW {
    font-size: 20px;
    color: #fbc000;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #636363;
    margin-right: 24px; }
.about {
    padding: 0 0 24px 24px;
    border-radius: 16px;
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
    border: 1px solid $neutrals6;
    .grey {
        color: #6b7185;
        font-size: 12px; }
    h3 {
        color: #a1a6a6;
        font-size: 16px; }
    p {
        color: #ffffff;
        font-size: 18px; }
    .owner {
        width: 100%;
        padding-top: 30px; }
    @include m {
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; }
    .flexBox {
        display: flex;
        padding-top: 20px;
        justify-content: flex-start;
        .classBox {
            width: 200px;
            margin-right: 40px; } } }


