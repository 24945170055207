@import "../../styles/helpers";

.container {
    position: relative;
    width: 65%;
    margin: 180px auto;
    min-height: 400px;
    box-shadow: #e0e0e0 0px 2px 10px;
    border-radius: 20px;
    padding: 1rem;
    @media only screen and (max-width: "474px") {
        width: 88%;
        margin: 20px auto; }
    .card {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 16px;
        .title {
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            min-width: 0px;
            width: 100%;
            display: flex;
            padding: 0px;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            margin: -8px; }
        .borderBox {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            width: 100%;
            border-radius: 16px;
            padding: 40px;
            min-height: 370px;
            line-height: 370px;
            border: 1px solid rgb(245, 243, 243);
            p {
                box-sizing: border-box;
                margin: 0px;
                min-width: 0px;
                font-weight: 400;
                font-size: 16px;
                color: rgb(136, 141, 155);
                text-align: center; } } } }
