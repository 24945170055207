@import "../../styles/helpers";

.header {
    position: relative;
    z-index: 20;
    padding: 20px 0;
    border-bottom: 1px solid $neutrals6;
    @include m {
        padding: 48px 0 24px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.container {
    display: flex;
    align-items: center;
    & > .button {
        margin-right: 12px;
        @include m {
            display: none; } } }

.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    height: 60px;
    margin-right: 32px;
    @media only screen and (max-width: "474px") {
        height: 40px;
        width: 149px;
        img {
            height: 40px;
            max-width: 149px; } }
    @include d {
        margin-right: auto; }
    img {
        width: 100%; } }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    @include d {
        flex-grow: 0; }
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        margin: 0;
        padding: 140px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals1; }
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }

.nav {
    display: flex;
    margin-right: auto;
    border-left: 2px solid $neutrals6;
    @include d {
        display: none; }
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.linkGrey {
    margin-left: 32px;
    @include dm-sans;
    line-height: 40px;
    color: #999;
    transition: color .2s;
    font-size: 20px;
    @include m {
        margin-left: 0;
        font-size: 24px;
        line-height: 64px; } }
.link {
    margin-left: 32px;
    @include dm-sans;
    line-height: 40px;
    color: #fff;
    transition: color .2s;
    font-size: 20px;
    @include m {
        margin-left: 0;
        font-size: 24px;
        line-height: 64px; }
    &:hover,
    &.active {
        color: #fff; }
    &:nth-child(n+7) {
        display: none;
        @include m {
            display: block; } } }
.linkMenu {
    margin-left: 32px;
    display: inline-block;
    @include dm-sans;
    line-height: 40px;
    color: #fff;
    transition: color .2s;
    font-size: 20px;
    position: relative;
    background: url("../../assets/images/icon_menu.png") no-repeat right center;
    padding-right: 20px;
    background-size: 15px auto;
    @include m {
        margin-left: 0;
        font-size: 24px;
        line-height: 64px;
        position: static;
        background: none;
        .text {
            display: none; } }
    &:hover {
        color: #fff;
        padding-right: 20px;
        background: url("../../assets/images/icon_menu.png") no-repeat right center;
        background-size: 15px auto;
        .menu {
            display: block;
            position: absolute;
            left: -30px;
            top: 40px;
            padding: 20px;
            border-radius: 15px;
            background: #23262F;
            @include m {
                background: none;
                position: static;
                padding: 0;
                border-radius: 0; }
            li {
                display: block;
                padding: 0;
                margin: 0;
                a {
                    margin: 0!important; } } } }
    &.active {
        color: #fff; }
    &:nth-child(n+7) {
        display: none;
        @include m {
            display: block; } } }
.menu {
    display: none;
    @include m {
        display: block;
        position: static;
        padding: 0px;
        border-radius: 0;
        background: none; } }
.search {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } } }

.notification {
    margin-right: 24px;
    @include m {
        margin-right: 20px; } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $neutrals4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }
