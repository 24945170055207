@import "../../../styles/helpers";
.titleW {
    font-size: 20px;
    color: #fbc000;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #636363;
    margin-right: 24px;
    margin-bottom: 20px; }
.body {
    margin-top: 36px;
    padding: 0 0 24px 24px;
    border-radius: 16px;
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
    border: 1px solid $neutrals6;
    .title {
        font-weight: bold;
        color: #a1a6b6;
        font-size: 14px; }
    @include m {
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; }
    .flexBox {
        display: flex;
        padding-bottom: 10px;
        padding-top: 10px;
        justify-content: flex-start;
        .flexItem {
            width: 24%;
            .attrValue {
                color: #ffffff;
                font-size: 14px;
                margin-left: 10px;
                line-height: 24px; }
            .imgFlex {
                display: flex;
                justify-content: flex-start; }
            img {
                width: 24px;
                height: 24px; } } } }


