@import "../../styles/helpers";

.btnItem {
    background: #fbc000;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    color: #000;
    &:hover {
        background: #fbc000;
        color: #000; } }
.row1 {
    text-align: center;
    padding: 30px; }
.table {
    .row {
        display: flex;
        padding-top: 12px;
        justify-content: flex-start;
        &:first-child {
            font-weight: 500;
            @include dark {
                border-color: $neutrals3; } }
        &:nth-child(2) {
            padding-top: 22px;
            @media only screen and (max-width: "474px") {
                .col {
                    .label {
                        display: none; } } } } } }

.col {
    display: flex;
    justify-content: space-between;
    .label {
        width: 20%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .label1 {
        width: 29%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .inputWrap {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputWrap1 {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputItem {
        width: 59%;
        display: flex;
        justify-content: space-between; }
    .inputItem1 {
        width: 39%;
        display: flex;
        justify-content: flex-start; }

    .inputItem2 {
        width: 70%;
        display: flex;
        justify-content: flex-start; }
    .input {
        background: none;
        border: 2px solid #353945;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: #ffffff;
        padding-left: 15px; }

    &:first-child {
        color: $neutrals4;
        width: 100%; }
    &:nth-child(2) {
        margin-left: auto;
        padding-left: 30px;
        font-weight: 500; }
    @media only screen and (max-width: "474px") {
        display: block;

        .label {
            width: 100%;
            text-align: left; }
        .label1 {
            width: 100%;
            text-align: left; }
        .inputWrap {
            width: 100%;
            display: block;
            .dropdown {
                margin-bottom: 0; } }
        .inputItem1 {
            padding-bottom: 12px;
            width: 100%; }
        .inputItem {
            width: 100%;
            display: block; } } }

.btns {
    margin-top: 32px; }
.button {
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 8px; } }
.dropdown {
    width: 256px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.dropdown1 {
    width: 166px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.container {
    position: relative;
    width: 65%;
    margin: 180px auto;
    box-shadow: #e0e0e0 0px 2px 10px;
    border-radius: 20px;
    padding: 1rem;
    @media only screen and (max-width: "474px") {
        width: 88%;
        margin: 20px auto;
        .borderBox {
            padding: 40px 6px 0!important; } }
    .card {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 16px;
        .borderBox {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            width: 100%;
            border-radius: 16px;
            padding: 40px 40px 0;
            min-height: 320px;
            border: 1px solid rgb(245, 243, 243);
            .title {
                color: #ffffff;
                padding-bottom: 40px;
                text-align: center; }
            p {
                box-sizing: border-box;
                margin: 0px;
                min-width: 0px;
                font-weight: 400;
                font-size: 16px;
                color: rgb(136, 141, 155);
                text-align: center; } } } }
