@import "../../../styles/helpers";
.titleW {
    font-size: 20px;
    color: #fbc000;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #636363;
    margin-right: 24px;
    margin-bottom: 12px; }
.control {
    padding: 0 0 24px 24px;
    margin-top: 36px;
    border-radius: 16px;
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12);
    border: 1px solid $neutrals6;
    @include m {
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals3; } }

.head {
    display: flex;
    align-items: center; }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.info {
    @include body-bold-2;
    color: $neutrals4;
    span {
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }

.cost {
    display: flex; }

.price {
    @include body-bold-1;
    &:nth-child(2) {
        color: $neutrals4; }
    &:not(:last-child) {
        margin-right: 12px; } }

.btns {
    display: flex;
    margin: 32px -4px 0;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 8px; } }

.text {
    font-weight: 500;
    color: $neutrals4;
    span {
        margin-left: 12px;
        font-weight: 400; }
    pre {
        word-wrap: break-word;
        word-break: keep-all;
        font-family: 'Poppins', sans-serif;
        font-size: 16px; } }

.percent {
    font-weight: 500;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.foot {
    margin-top: 32px;
    .button {
        width: 100%; } }

.note {
    margin-top: 32px;
    color: $neutrals4; }
