@import "../../styles/helpers";
.btnGrey {
    background: #cccccc!important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    height: 30px;
    line-height: 30px; }
.button {
    width: 100%;
    background: #fbc000!important;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    color: #000;
    &:hover {
        background: #fbc000;
        color: #000; }
    &:not(:last-child) {
        margin-bottom: 8px; } }
.flexBox {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 0 0;
  .flexItem {
    width: 30%;
    .plusStyle {
      height: 115px;
      line-height: 115px;
      font-size: 48px;
      text-align: center;
      border: 1px solid #fff; }
    p {
      text-align: center; }
    .imgWrap {
      height: 150px; }
    img {
      width: 100%; } } }
.flexBox {
  display: flex;
  justify-content: center;
  padding: 50px 0 0 0; }
.titleModel {
  color: #ffffff;
  font-size: 20px;
  text-align: center; }
.btnItem {
    margin-right: 20px;
    background: #fbc000;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    color: #000;
    &:hover {
        background: #fbc000;
        color: #000; } }
.btnBox {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 40px;
    button {
        width: 160px; }
    .btnItemNone {
        margin-right: 20px;
        background: none;
        box-shadow: 0 0 0 2px #353945 inset;
        color: #999; }
    .btnItem1 {
        margin-right: 20px;
        background: none;
        box-shadow: 0 0 0 2px #353945 inset;
        color: #FCFCFD; }
    .btnItem2 {
        margin-right: 20px;
        background: none;
        width: 180px;
        box-shadow: 0 0 0 2px #353945 inset;
        color: #FCFCFD; } }
.titleW {
    font-size: 20px;
    color: #fff;
    margin-top: 30px; }
.section {
    padding: 96px 0;
    @include x {
        padding: 80px 0; }
    @include m {
        padding: 64px 0; } }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 384px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.preview {
    position: relative;
    img {
        width: 100%;
        border-radius: 16px; } }

.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%); }

.title {
    margin-bottom: 8px; }

.cost {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }

.price {
    @include dm-sans;
    font-size: 16px;
    line-height: 30px;
    &:not(:last-child) {
        margin-right: 8px; } }

.counter {
    @include button-1;
    color: $neutrals4; }

.info {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.users {
    margin-bottom: auto; }

.control {
    pre {
        word-break: break-all;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        word-wrap: break-word; }
    @include m {
        margin-top: 32px; } }
