@import "../../styles/helpers";
.tips {
    color: #fff;
    font-size: 12px;
    span {
        color: #ff3300; } }
.available {
    padding-bottom: 6px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 17px; }
.available1 {
    padding-top: 12px;
    font-size: 12px;
    font-weight: bold;
    color: #999;
    line-height: 17px; }
.tokenList {
    padding-top: 50px;
    .titleModel {
        text-align: center;
        padding-bottom: 20px; }
    li {
        border-bottom: 1px solid #ffffff;
        height: 60px;
        line-height: 60px;
        padding: 0 50px 0 20px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .icon_sel {
            position: absolute;
            right: 0;
            top: 0;
            img {
                width: 20px;
                height: 20px; } }
        img {
            width: 32px;
            margin-right: 10px; } } }
.row1 {
    text-align: center;
    padding: 30px; }
.table {
    .row {
        display: flex;
        padding: 0 0 22px;
        justify-content: flex-start;
        &:first-child {
            border-bottom: 1px solid $neutrals6;
            font-weight: 500;
            @include dark {
                border-color: $neutrals3; } }
        &:nth-child(2) {
            padding-top: 22px; } } }
.col2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .betInfo {
        width: 100%;
        position: relative;
        .betSelete {
            height: 60px;
            display: flex;
            border: 1px solid #fff;
            box-shadow: 0 5px 15px 0 #0f154d;
            border-radius: 9px;
            padding: 0 10px;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
            align-items: center;
            img {
                width: 32px;
                margin-right: 22px; } }
        .betText {
            height: 20px;
            font-size: 13px;
            font-weight: 600;
            padding-bottom: 12px; } }
    .betImg {
        flex-shrink: 0;
        justify-content: center;
        width: 58px;
        height: 80px;
        padding-top: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        img {
            width: 26px; } } }
.col {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tokenSelect {
        position: relative;
        width: 100%;
        height: 50px;
        border: 1px solid #f1f3f7;
        border-radius: 8px;
        margin-top: 10px;
        padding: 0 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px; }
        .arrow {
            border-right: 1px solid #f1f3f7;
            border-top: 1px solid #f1f3f7;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            position: absolute;
            right: 20px;
            top: 19px; } }
    .label {
        width: 20%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .label1 {
        width: 29%;
        color: #ffffff;
        height: 48px;
        line-height: 48px;
        text-align: right; }
    .inputWrap {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputWrap1 {
        width: 70%;
        text-align: left;
        display: flex;
        justify-content: space-between; }
    .inputItem {
        width: 59%;
        display: flex;
        justify-content: space-between; }
    .inputItem1 {
        width: 39%;
        display: flex;
        justify-content: flex-start; }
    .input {
        background: none;
        border: 2px solid #353945;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: #ffffff;
        padding-left: 15px; }
    .input1 {
        background: none;
        width : -webkit-calc(100% - 105px);
        width: -moz-calc(100% - 105px);
        width: calc(100% - 105px);
        height: 48px;
        line-height: 48px;
        color: #ffffff;
        padding-left: 15px; } }

.assetsMax {
    color: #000;
    background: #ffcc00;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    min-width: 46px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px; }
.btns {
    margin-top: 32px; }
.btnItem {
    background: #fbc000;
    color: #000;
    border-radius: 5px; }
.btnItem:hover {
    background: #fbc000; }

.button {
    background: #ffcc00;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 8px; } }
.dropdown {
    width: 256px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.dropdown1 {
    width: 166px;
    margin-right: auto;
    @include t {
        width: 100%;
        margin: 0 0 32px; } }
.container {
    position: relative;
    width: 455px;
    margin: 120px auto;
    box-shadow: #e0e0e0 0px 2px 10px;
    border-radius: 20px;
    padding: 1rem;
    @media only screen and (max-width: "474px") {
        width: 88%;
        margin: 20px auto; }
    .card {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 16px;
        .borderBox {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            width: 100%;
            border-radius: 16px;
            padding: 40px 20px 0;
            min-height: 320px;
            border: 1px solid rgb(245, 243, 243);
            .title {
                color: #ffffff;
                padding-bottom: 10px;
                padding-top: 10px;
                text-align: left;
                margin-bottom: 0!important; }
            p {
                box-sizing: border-box;
                margin: 0px;
                min-width: 0px;
                font-weight: 400;
                font-size: 16px;
                color: rgb(136, 141, 155);
                text-align: center; } } } }
